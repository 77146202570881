import { createApi } from '@reduxjs/toolkit/query/react';

import { Recruitment } from 'src/models/recruitment';
import { User } from 'src/models/user';

import { axiosBaseQuery } from '../redux';
import { recruitmentTag } from '../redux/constants';

export const recruitmentApi = createApi({
    reducerPath: 'recruitment',
    tagTypes: [recruitmentTag],
    baseQuery: axiosBaseQuery(),
    endpoints: (builder) => ({
        getRecruitments: builder.query<Recruitment[], void>({
            query: () => ({ url: '/recruitments', method: 'GET' }),
            providesTags: (result) =>
                result ? [...result.map(({ id }) => ({ type: recruitmentTag, id })), recruitmentTag] : [recruitmentTag]
        }),
        getRecruitment: builder.query<Recruitment, Pick<Recruitment, 'id'>>({
            query: ({ id }) => ({ url: `/recruitments/one/${id}`, method: 'GET' }),
            providesTags: (result) => (result ? [{ type: recruitmentTag, id: result.id }] : [])
        }),
        getInstructorRecruitments: builder.query<Recruitment[], Pick<User, 'id'>>({
            query: ({ id }) => ({ url: `instructors/one/${id}/recruitments`, method: 'GET' }),
            providesTags: (_1, _2, { id }) => [{ type: recruitmentTag, id }]
        })
    })
});

export const { useGetRecruitmentsQuery, useGetRecruitmentQuery, useGetInstructorRecruitmentsQuery } = recruitmentApi;
