import { ThemeProvider } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import { FC, createContext, PropsWithChildren, useState } from 'react';
import { themeCreator } from './base';

export const ThemeContext = createContext((themeName: string): void => {});

const ThemeProviderWrapper: FC<PropsWithChildren> = (props) => {
    const [themeName, _setThemeName] = useState('TeamsharqTheme');
    const theme = themeCreator(themeName);
    const setThemeName = (themeName: string): void => {
        localStorage.setItem('appTheme', themeName);
        _setThemeName(themeName);
    };

    return (
        <StylesProvider injectFirst>
            <ThemeContext.Provider value={setThemeName}>
                <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
            </ThemeContext.Provider>
        </StylesProvider>
    );
};

export default ThemeProviderWrapper;
