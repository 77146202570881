import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { agentApi } from 'src/services/agentService';
import { paymentApiService } from 'src/services/paymentService';
import { recruitmentApi } from 'src/services/recruitmentService';
import { userApi } from 'src/services/userService';
import { workspaceApi } from 'src/services/workspaceService';

export const store = configureStore({
    reducer: {
        [agentApi.reducerPath]: agentApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [workspaceApi.reducerPath]: workspaceApi.reducer,
        [recruitmentApi.reducerPath]: recruitmentApi.reducer,
        [paymentApiService.reducerPath]: paymentApiService.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            agentApi.middleware,
            userApi.middleware,
            workspaceApi.middleware,
            recruitmentApi.middleware,
            paymentApiService.middleware
        )
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
