import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import Loader from 'src/components/Loader';
import Authenticated from 'src/components/Authenticated';
import DefaultLayout from 'src/layouts/DefaultLayout';

import usersRoutes from './users';
import workspacesRoutes from './workspaces';
import recruitmentsRoutes from './recruitments';

const Dashboard = Loader(lazy(() => import('src/pages/Dashboard')));

const router: RouteObject[] = [
    {
        path: '/',
        element: (
            <Authenticated>
                <DefaultLayout />
            </Authenticated>
        ),
        children: [
            {
                path: '/',
                element: <Navigate to="users" replace />
            },
            {
                path: 'dashboard',
                element: <Dashboard />
            },
            {
                path: 'users',
                children: usersRoutes
            },
            {
                path: 'workspaces',
                children: workspacesRoutes
            },
            {
                path: 'recruitments',
                children: recruitmentsRoutes
            }
        ]
    }
];

export default router;
