import { Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import axios from 'axios';
import { Formik } from 'formik';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Environment } from 'src/environment';
import useAuth from 'src/hooks/useAuth';
import useRefMounted from 'src/hooks/useRefMounted';
import { getApiUrl } from 'src/utils/api';
import * as Yup from 'yup';

const LoginForm: FC = () => {
    const { login } = useAuth();
    const isMountedRef = useRefMounted();
    const { t } = useTranslation();

    return (
        <Formik
            initialValues={{
                environment: localStorage.getItem(Environment.environmentKey) ?? Environment.Staging,
                login: '',
                password: '',
                submit: null
            }}
            validationSchema={Yup.object().shape({
                environment: Yup.string().oneOf([Environment.Staging, Environment.Production], t('Select environment')),
                login: Yup.string().max(255).required(t('The login field is required')),
                password: Yup.string().max(255).required(t('The password field is required'))
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }): Promise<void> => {
                try {
                    localStorage.setItem(Environment.environmentKey, values.environment);
                    axios.defaults.baseURL = getApiUrl();

                    await login(values.login, values.password);

                    if (isMountedRef.current) {
                        setStatus({ success: true });
                        setSubmitting(false);
                    }
                } catch (err) {
                    console.error(err);
                    if (isMountedRef.current) {
                        setStatus({ success: false });
                        // setErrors({ submit: err.message });
                        setSubmitting(false);
                    }
                }
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }): JSX.Element => (
                <form noValidate onSubmit={handleSubmit}>
                    <FormControl margin="normal" fullWidth>
                        <InputLabel id="env-label">{t('Environment')}</InputLabel>
                        <Select
                            labelId="env-label"
                            label={t('Environment')}
                            value={values.environment}
                            inputProps={{
                                name: 'environment'
                            }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        >
                            <MenuItem value={Environment.Staging}>{t('Staging')}</MenuItem>
                            {/*<MenuItem value={Environment.Production}>{t('Production')}</MenuItem>*/}
                        </Select>
                    </FormControl>
                    <TextField
                        error={Boolean(touched.login && errors.login)}
                        fullWidth
                        margin="normal"
                        autoFocus
                        helperText={touched.login && errors.login}
                        label={t('Login')}
                        name="login"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.login}
                        variant="outlined"
                    />
                    <TextField
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        margin="normal"
                        helperText={touched.password && errors.password}
                        label={t('Password')}
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        value={values.password}
                        variant="outlined"
                    />

                    <Button
                        sx={{
                            mt: 3
                        }}
                        color="primary"
                        startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                        disabled={isSubmitting}
                        type="submit"
                        fullWidth
                        size="large"
                        variant="contained"
                    >
                        {t('Sign in')}
                    </Button>
                </form>
            )}
        </Formik>
    );
};

export default LoginForm;
