import { Environment } from 'src/environment';

// TODO: refactor
export const getApiUrl = (): string => {
    let apiUrlPrefix: string;

    const selectedEnvironment: string = localStorage.getItem(Environment.environmentKey || '') ?? '';
    switch (selectedEnvironment) {
        case Environment.Production:
            apiUrlPrefix = 'api';
            break;
        default:
        case Environment.Staging:
            apiUrlPrefix = 'api-staging';
            break;
    }

    if (apiUrlPrefix) {
        return `https://${apiUrlPrefix}.teamsharq.com/api/v1`;
    } else {
        return '';
    }
};
