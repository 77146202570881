import { Avatar, AvatarProps } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import useRefMounted from '../../hooks/useRefMounted';

export interface SecureAvatarProps extends AvatarProps {
    url: string;
}

const SecureAvatar = ({ url, ...props }: SecureAvatarProps) => {
    const isMountedRef = useRefMounted();
    const [image, setImage] = useState('');

    const getSecureImage = useCallback(async () => {
        try {
            const response = await axios.get(url, {
                responseType: 'blob',
                maxContentLength: 10240000,
                headers: { 'Content-Type': 'image/*' }
            });
            if (isMountedRef.current) {
                setImage(URL.createObjectURL(response.data));
            }
        } catch (err) {
            console.error(err);
        }
    }, [url, isMountedRef]);

    useEffect(() => {
        void getSecureImage();
    }, [getSecureImage]);

    return <Avatar src={image} {...props} />;
};

export default SecureAvatar;
