import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CssBaseline, Zoom } from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { SnackbarProvider } from 'notistack';
import { useEffect } from 'react';
import { useRoutes } from 'react-router';

import AppInit from './components/AppInit';
import useAuth from './hooks/useAuth';
import router from './router';
import ThemeProvider from './theme/ThemeProvider';
import { getApiUrl } from './utils/api';

function App() {
    const content = useRoutes(router);
    const auth = useAuth();

    useEffect(() => {
        const apiUrl = getApiUrl();
        if (apiUrl !== '') {
            axios.defaults.baseURL = apiUrl;
        } else {
            auth.logout();
        }
        dayjs.extend(duration);
        dayjs.extend(localizedFormat);
    }, []);

    return (
        <ThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SnackbarProvider
                    maxSnack={6}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    TransitionComponent={Zoom}
                >
                    <CssBaseline />
                    {auth.isInitialized ? content : <AppInit />}
                </SnackbarProvider>
            </LocalizationProvider>
        </ThemeProvider>
    );
}

export default App;
