import axios from 'axios';
import jwt_decode from 'jwt-decode';
import PropTypes from 'prop-types';
import { createContext, FC, ReactNode, useEffect, useReducer } from 'react';
import { Environment } from 'src/environment';
import { User } from 'src/models/user';

interface AuthState {
    isInitialized: boolean;
    isAuthenticated: boolean;
    user: User | null;
}

interface AuthContextValue extends AuthState {
    login: (email: string, password: string) => Promise<void>;
    logout: () => void;
}

interface AuthProviderProps {
    children: ReactNode;
}

type Action = {
    type: string;
    payload?: {
        isAuthenticated: boolean;
        user: User | null;
    };
};

const initialAuthState: AuthState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null
};

const isAuthTokenValid = (accessToken: string) => {
    let decoded: any;
    try {
        decoded = jwt_decode(accessToken);
    } catch {
        return false;
    }

    const currentTime = Date.now() / 1000;
    return !(!decoded || decoded.exp < currentTime);
};

const setSession = (accessToken: string | null): void => {
    if (accessToken) {
        localStorage.setItem(Environment.accessTokenKey, accessToken);
        axios.defaults.headers.common['x-api-token'] = accessToken;
    } else {
        localStorage.removeItem(Environment.accessTokenKey);
        delete axios.defaults.headers.common['x-api-token'];
    }
};

const handlers: Record<string, (state: AuthState, action: Action) => AuthState> = {
    INITIALIZE: (state: AuthState, action: Action): AuthState => {
        const { isAuthenticated, user } = action.payload!;

        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user
        };
    },
    LOGIN: (state: AuthState, action: Action): AuthState => {
        const { user } = action.payload!;

        return {
            ...state,
            isAuthenticated: true,
            user
        };
    },
    LOGOUT: (state: AuthState): AuthState => ({
        ...state,
        isAuthenticated: false,
        user: null
    })
};

const reducer = (state: AuthState, action: Action): AuthState =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
    ...initialAuthState,
    login: () => Promise.resolve(),
    logout: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
    const { children } = props;
    const [state, dispatch] = useReducer(reducer, initialAuthState);

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            try {
                const accessToken = localStorage.getItem(Environment.accessTokenKey);

                if (accessToken && isAuthTokenValid(accessToken)) {
                    setSession(accessToken);

                    // const response = await axios.get<UserPayload>('/recruiters/alias/me');

                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated: true,
                            user: null
                            // user: parseUser(response.data)
                        }
                    });
                } else {
                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated: false,
                            user: null
                        }
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: 'INITIALIZE',
                    payload: {
                        isAuthenticated: false,
                        user: null
                    }
                });
            }
        };

        void initialize();
    }, []);

    const login = async (login: string, password: string): Promise<void> => {
        const response = await axios.post<{ access_token: string; user: User }>(
            `/users/su/login`,
            `{ "login": "${login}", "password": "${password}", "tenant": "cmit" }`,
            {
                headers: { 'Content-Type': 'application/json' }
            }
        );
        const { access_token: accessToken, user } = response.data;

        setSession(accessToken);
        dispatch({
            type: 'LOGIN',
            payload: {
                isAuthenticated: true,
                user
            }
        });
    };

    const logout = async (): Promise<void> => {
        setSession(null);
        dispatch({ type: 'LOGOUT' });
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                login,
                logout
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default AuthContext;
