import { Box, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { FC } from 'react';

interface LogoProps {
  variant?: 'light' | 'dark';
}

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        width: 250px;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const LogoSignWrapper = styled(Box)(
  () => `
        width: 250px;
`
);

const Logo: FC<LogoProps> = ({ variant = 'light' }) => {
  return (
    <LogoWrapper to="/">
      <LogoSignWrapper>
        <img src={`static/images/logo/teamsharq-logo-full-${variant}.svg`} alt="logo" />
      </LogoSignWrapper>
    </LogoWrapper>
  );
};

export default Logo;
