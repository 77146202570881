import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loader from 'src/components/Loader';

const Users = Loader(lazy(() => import('src/pages/Users')));
const SingleUser = Loader(lazy(() => import('src/pages/Users/single')));

const usersRoutes = [
    {
        path: '',
        element: <Navigate to="list" replace />
    },
    {
        path: 'list',
        element: <Users />
    },
    {
        path: 'single',
        children: [
            {
                path: '',
                element: <Navigate to="1" replace />
            },
            {
                path: ':userId',
                element: <SingleUser />
            }
        ]
    }
];

export default usersRoutes;
