import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loader from 'src/components/Loader';

const Recruitments = Loader(lazy(() => import('src/pages/Recruitments')));
const SingleRecruitment = Loader(lazy(() => import('src/pages/Recruitments/single')));

const recruitmentsRoutes = [
    {
        path: '',
        element: <Navigate to="list" replace />
    },
    {
        path: 'list',
        element: <Recruitments />
    },
    {
        path: 'single',
        children: [
            {
                path: '',
                element: <Navigate to="1" replace />
            },
            {
                path: ':recruitmentId',
                element: <SingleRecruitment />
            }
        ]
    }
];

export default recruitmentsRoutes;
