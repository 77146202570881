import { FC, useState, createContext, PropsWithChildren } from 'react';
type SidebarContextType = {
    sidebarToggle: any;
    toggleSidebar: () => void;
    closeSidebar: () => void;
};

export const SidebarContext = createContext<SidebarContextType>({} as SidebarContextType);

export const SidebarProvider: FC<PropsWithChildren> = ({ children }) => {
    const [sidebarToggle, setSidebarToggle] = useState(false);
    const toggleSidebar = () => {
        setSidebarToggle(!sidebarToggle);
    };
    const closeSidebar = () => {
        setSidebarToggle(false);
    };

    return <SidebarContext.Provider value={{ sidebarToggle, toggleSidebar, closeSidebar }}>{children}</SidebarContext.Provider>;
};
