import { Box, Card, Container, styled, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Logo from 'src/components/LogoSign';
import LoginForm from 'src/pages/Login/LoginForm';

const MainContent = styled(Box)(
    ({ theme }) => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-top: ${theme.spacing(7)};
`
);

const TopWrapper = styled(Box)(
    () => `
  display: flex;
  width: 100%;
  flex: 1;
  padding: 20px;
`
);

function Login() {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>Login - Basic</title>
            </Helmet>
            <MainContent>
                <TopWrapper>
                    <Container maxWidth="sm">
                        <Logo />
                        <Card
                            sx={{
                                mt: 3,
                                px: 4,
                                pt: 5,
                                pb: 3
                            }}
                        >
                            <Box>
                                <Typography
                                    variant="h2"
                                    sx={{
                                        mb: 1
                                    }}
                                >
                                    {t('Sign in')}
                                </Typography>
                            </Box>
                            <LoginForm />
                        </Card>
                    </Container>
                </TopWrapper>
            </MainContent>
        </>
    );
}

export default Login;
