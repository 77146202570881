import { parseUser, User, UserPayload } from './user';
import { Recruitment } from './recruitment';

export interface Workspace {
    id: string;
    tenant: string;
    course: Recruitment;
    creator: User;
    owner: User;
    members: User[];
    readers: User[];
    created_time: Date;
    updated_time: Date;
    attributes: WorkspaceAttributes;
    disks: any[];
    volumes: any[];
    status: WorkspaceStatus;
    console_status: WorkspaceStatus;
    provisioning_status: WorkspaceProvisioningStatus;
    region: string;
    orphaned: boolean;
    pending_events: WorkspaceEvent[];
    started_events: WorkspaceEvent[];
    retrying_events: WorkspaceEvent[];
    committed_events: WorkspaceEvent[];
    failed_events: WorkspaceEvent[];
}

export interface WorkspaceAttributes {
    cpu: number;
    current_cpu?: number;
    mem: number;
    current_mem?: number;
}

export interface WorkspaceImage {
    title: string;
    name: string;
}

export interface WorkspaceEvent {
    id: string;
    seq: number;
    created_time: Date;
    started_time: Date;
    failed_time: Date;
    committed_time: Date;
    owner_id: string;
    state: WorkspaceEventState;
    fail_reason: number;
    retries_count: number;
    retries_limit: number;
    description: string;
    message: string;
}

export enum WorkspaceStatus {
    NoState = 'NO_STATE',
    Running = 'RUNNING',
    Blocked = 'BLOCKED',
    Paused = 'PAUSED',
    Shutdown = 'SHUTDOWN',
    Shutoff = 'SHUTOFF',
    Crashed = 'CRASHED',
    PmSuspended = 'SUSPENDED'
}

export enum WorkspaceProvisioningStatus {
    Started = 'STARTED',
    Finished = 'FINISHED'
}

export enum WorkspaceEventState {
    Pending = 'PENDING',
    Started = 'STARTED',
    Committed = 'COMMITTED',
    Failed = 'FAILED',
    Unknown = 'UNKNOWN'
}

export const parseWorkspace = (payload: WorkspacePayload): Workspace => {
    return {
        ...payload,
        id: payload.id,
        creator: parseUser(payload.creator),
        owner: parseUser(payload.owner),
        members: payload.members.map(parseUser),
        readers: payload.readers.map(parseUser)
    };
};

export interface WorkspacePayload {
    id: string;
    tenant: string;
    course: Recruitment;
    creator: UserPayload;
    owner: UserPayload;
    members: UserPayload[];
    readers: UserPayload[];
    created_time: Date;
    updated_time: Date;
    attributes: WorkspaceAttributes;
    disks: any[];
    volumes: any[];
    status: WorkspaceStatus;
    console_status: WorkspaceStatus;
    provisioning_status: WorkspaceProvisioningStatus;
    region: string;
    orphaned: boolean;
    pending_events: WorkspaceEvent[];
    started_events: WorkspaceEvent[];
    retrying_events: WorkspaceEvent[];
    committed_events: WorkspaceEvent[];
    failed_events: WorkspaceEvent[];
}
