export interface User {
    id: string;
    email: string;
    tenant: string;
    name: string;
    bio: string;
    status: UserStatus;
    role: UserRole;
}

export enum UserRole {
    Student = 'STUDENT',
    Instructor = 'INSTRUCTOR',
    Admin = 'ADMIN'
}

export enum UserStatus {
    Active = 'ACTIVE',
    Blocked = 'BLOCKED',
    Deleted = 'DELETED'
}

export const parseUser = (payload: UserPayload): User => {
    let role = UserRole.Student;
    if (payload.id.startsWith('su-')) {
        // TODO refactor su
        role = UserRole.Admin;
    } else if (payload.iam_instructor) {
        role = UserRole.Instructor;
    }

    const { first_name, last_name, ...rest } = payload;

    return {
        ...rest,
        name: `${first_name} ${last_name}`,
        role
    };
};

export interface UserPayload {
    id: string;
    tenant: string;
    login: string;
    bio: string;
    status: UserStatus;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    iam_instructor: boolean;
    iam_student: boolean;
}
