import { FC, Suspense } from "react";
import SuspenseLoader from "../SuspenseLoader";

const Loader = <T,>(Component: FC<T>) => (props: T) =>
    (
        <Suspense fallback={<SuspenseLoader />}>
            <Component {...props} />
        </Suspense>
    );

export default Loader;