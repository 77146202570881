import { alpha, Box, IconButton, styled, Tooltip, tooltipClasses, TooltipProps, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PowerSettingsNewTwoToneIcon from '@mui/icons-material/PowerSettingsNewTwoTone';
import { useNavigate } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.colors.alpha.trueWhite[100],
            color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
            boxShadow: theme.shadows[24],
            fontWeight: 'bold',
            fontSize: theme.typography.pxToRem(12)
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.colors.alpha.trueWhite[100]
        }
    })
);

function SidebarFooter() {
    const { t } = useTranslation();
    const theme = useTheme();
    const { logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = async (): Promise<void> => {
        try {
            await logout();
            navigate('/');
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Box
            sx={{
                px: 2,
                height: 60
            }}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
        >
            <Typography variant="subtitle1" sx={{ color: `${theme.colors.alpha.trueWhite[70]}` }}>
                &copy; {new Date().getFullYear()} Teamsharq Sp. z o.o.
            </Typography>

            <LightTooltip placement="top" arrow title={`${t('Sign out')}`}>
                <IconButton
                    sx={{
                        background: `${theme.colors.alpha.trueWhite[10]}`,
                        color: `${theme.colors.alpha.trueWhite[70]}`,
                        transition: `${theme.transitions.create(['all'])}`,

                        '&:hover': {
                            background: `${alpha(theme.colors.alpha.trueWhite[100], 0.2)}`,
                            color: `${theme.colors.alpha.trueWhite[100]}`
                        }
                    }}
                    onClick={handleLogout}
                >
                    <PowerSettingsNewTwoToneIcon fontSize="small" />
                </IconButton>
            </LightTooltip>
        </Box>
    );
}

export default SidebarFooter;
