import { createApi } from '@reduxjs/toolkit/query/react';
import { parseWorkspace, Workspace, WorkspacePayload } from 'src/models/workspace';
import { axiosBaseQuery } from '../redux';
import { workspaceTag } from '../redux/constants';

export const workspaceApi = createApi({
    reducerPath: 'workspace',
    baseQuery: axiosBaseQuery(),
    tagTypes: [workspaceTag],
    endpoints: (builder) => ({
        getWorkspaces: builder.query<Workspace[], void>({
            query: () => ({ url: `workspaces`, method: 'GET' }),
            transformResponse: (response: WorkspacePayload[]) => response.map(parseWorkspace),
            providesTags: (result) =>
                result ? [...result.map(({ id }) => ({ type: workspaceTag, id })), workspaceTag] : [workspaceTag]
        }),
        getWorkspace: builder.query<Workspace, Pick<Workspace, 'id'>>({
            query: ({ id }) => ({ url: `workspaces/one/${id}`, method: 'GET' }),
            transformResponse: (response: WorkspacePayload) => parseWorkspace(response),
            providesTags: (_1, _2, { id }) => [{ type: workspaceTag, id }]
        }),
        getRecruitmentWorkspaces: builder.query<Workspace[], Pick<Workspace, 'id'>>({
            query: ({ id }) => ({ url: `recruitments/one/${id}/workspaces`, method: 'GET' }),
            transformResponse: (response: WorkspacePayload[]) => response.map(parseWorkspace),
            providesTags: (_1, _2, { id }) => [{ type: workspaceTag, id }]
        })
    })
});

export const { useGetWorkspacesQuery, useGetWorkspaceQuery, useGetRecruitmentWorkspacesQuery } = workspaceApi;
