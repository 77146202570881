import { createApi } from '@reduxjs/toolkit/query/react';

import { parseUser, User, UserPayload } from 'src/models/user';

import { axiosBaseQuery } from '../redux';
import { userTag } from '../redux/constants';

export const userApi = createApi({
    reducerPath: 'user',
    baseQuery: axiosBaseQuery(),
    tagTypes: [userTag],
    endpoints: (builder) => ({
        getUsers: builder.query<User[], void>({
            query: () => ({ url: `recruiters`, method: 'GET' }),
            transformResponse: (response: UserPayload[]) => response.map(parseUser),
            providesTags: (result) => (result ? [...result.map(({ id }) => ({ type: userTag, id })), userTag] : [userTag])
        }),
        getUser: builder.query<User, string>({
            query: (id) => ({ url: `recruiters/one/${id}`, method: 'GET' }),
            transformResponse: (response: UserPayload) => parseUser(response),
            providesTags: (_1, _2, id) => [{ type: 'User', id }]
        }),
        promoteUser: builder.mutation<void, Pick<User, 'id'>>({
            query: (data) => ({ url: 'instructors', method: 'PUT', data }),
            invalidatesTags: (_1, _2, { id }) => [{ type: userTag, id }]
        }),
        getRecruitmentInstructors: builder.query<User[], Pick<User, 'id'>>({
            query: ({ id }) => ({ url: `recruitments/one/${id}/instructors`, method: 'GET' }),
            transformResponse: (response: UserPayload[]) => response.map(parseUser),
            providesTags: (_1, _2, { id }) => [{ type: userTag, id }]
        }),
        getRecruitmentStudents: builder.query<User[], Pick<User, 'id'>>({
            query: ({ id }) => ({ url: `recruitments/one/${id}/students/enrolled`, method: 'GET' }),
            transformResponse: (response: UserPayload[]) => response.map(parseUser),
            providesTags: (_1, _2, { id }) => [{ type: userTag, id }]
        })
    })
});

export const {
    useGetUsersQuery,
    useGetUserQuery,
    usePromoteUserMutation,
    useGetRecruitmentInstructorsQuery,
    useGetRecruitmentStudentsQuery
} = userApi;
