import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import ComputerTwoToneIcon from '@mui/icons-material/ComputerTwoTone';
import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone';
import SchoolIcon from '@mui/icons-material/School';
import type { ReactNode } from 'react';

export interface MenuItem {
    link?: string;
    icon?: ReactNode;
    badge?: string;
    badgeTooltip?: string;

    items?: MenuItem[];
    name: string;
}

export interface MenuItems {
    items: MenuItem[];
    heading: string;
}

const menuItems: MenuItems[] = [
    {
        heading: '',
        items: [
            {
                name: 'Dashboard',
                icon: <HealthAndSafetyTwoToneIcon />,
                link: '/dashboard'
            },
            {
                name: 'Workspaces',
                icon: <ComputerTwoToneIcon />,
                link: '/workspaces'
            },
            {
                name: 'Users',
                icon: <AssignmentIndTwoToneIcon />,
                link: '/users'
            }
            // {
            //     name: 'Recruitments',
            //     icon: SchoolIcon,
            //     link: '/recruitments'
            // }
        ]
    }
];

export default menuItems;
