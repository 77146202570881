import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loader from 'src/components/Loader';

const Workspaces = Loader(lazy(() => import('src/pages/Workspaces')));
const SingleWorkspace = Loader(lazy(() => import('src/pages/Workspaces/single')));

const workspacesRoutes = [
    {
        path: '',
        element: <Navigate to="list" replace />
    },
    {
        path: 'list',
        element: <Workspaces />
    },
    {
        path: 'single/:workspaceId',
        element: <SingleWorkspace />
    }
];

export default workspacesRoutes;
