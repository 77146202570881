import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { SidebarProvider } from './contexts/SidebarContext';
import { AuthProvider } from './contexts/AuthContext';
import ScrollTop from 'src/hooks/useScrollTop';
import { store } from './redux/store';

const container = document.getElementById('app');
const root = createRoot(container!);
root.render(
    <HelmetProvider>
        <Provider store={store}>
            <SidebarProvider>
                <BrowserRouter>
                    <ScrollTop />
                    <AuthProvider>
                        <App />
                    </AuthProvider>
                </BrowserRouter>
            </SidebarProvider>
        </Provider>
    </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
