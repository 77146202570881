import { Box, Typography, useTheme } from '@mui/material';

import useAuth from 'src/hooks/useAuth';
import SecureAvatar from 'src/components/SecureAvatar';
import { avatarService } from 'src/services/avatarService';

function SidebarTopSection() {
    const theme = useTheme();
    const { user } = useAuth();

    if (!user) {
        return null;
    }

    return (
        <Box
            sx={{
                textAlign: 'center',
                mx: 2,
                pt: 1,
                position: 'relative'
            }}
        >
            <SecureAvatar
                sx={{
                    width: 68,
                    height: 68,
                    mb: 2,
                    mx: 'auto'
                }}
                alt={user.name}
                url={avatarService.getUserAvatarUrl(user.id)}
            />

            <Typography
                variant="h4"
                sx={{
                    color: `${theme.colors.alpha.trueWhite[100]}`
                }}
            >
                {user.name}
            </Typography>
            <Typography
                variant="h6"
                sx={{
                    color: `${theme.colors.alpha.trueWhite[100]}`
                }}
            >
                {user.tenant}
            </Typography>
        </Box>
    );
}

export default SidebarTopSection;
