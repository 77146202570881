import { createApi } from '@reduxjs/toolkit/query/react';

import { Agent } from 'src/models/agent';

import { axiosBaseQuery } from '../redux';
import { agentTag } from '../redux/constants';

export const agentApi = createApi({
    reducerPath: 'agent',
    tagTypes: [agentTag],
    baseQuery: axiosBaseQuery(),
    endpoints: (builder) => ({
        getAgents: builder.query<Agent[], void>({
            query: () => ({ url: `agents`, method: 'GET' }),
            providesTags: [agentTag]
        })
    })
});

export const { useGetAgentsQuery } = agentApi;
