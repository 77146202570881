import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { Badge, Button, Collapse, ListItem, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

import PropTypes from 'prop-types';
import { FC, ReactNode, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';

interface SidebarMenuItemProps {
    children?: ReactNode;
    link?: string;
    icon?: any;
    badge?: string;
    badgeTooltip?: string;
    open?: boolean;
    active?: boolean;
    name: string;
}

const TooltipWrapper = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.colors.alpha.trueWhite[100],
        color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 'bold',
        borderRadius: theme.general.borderRadiusSm,
        boxShadow: '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)'
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.colors.alpha.trueWhite[100]
    }
}));

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
    children,
    link,
    icon,
    badge,
    badgeTooltip,
    open: openParent = false,
    active,
    name,
    ...rest
}) => {
    const [menuToggle, setMenuToggle] = useState<boolean>(openParent);
    const { t } = useTranslation();
    const { closeSidebar } = useContext(SidebarContext);

    const toggleMenu = (): void => {
        setMenuToggle((Open) => !Open);
    };

    if (children) {
        return (
            <ListItem component="div" className="Mui-children" key={name} {...rest}>
                <Button
                    className={menuToggle ? 'Mui-active' : undefined}
                    startIcon={icon}
                    endIcon={menuToggle ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon />}
                    onClick={toggleMenu}
                >
                    {badgeTooltip ? (
                        <TooltipWrapper title={badgeTooltip} arrow placement="right">
                            {badge === '' ? <Badge color="primary" variant="dot" /> : <Badge badgeContent={badge} />}
                        </TooltipWrapper>
                    ) : badge === '' ? (
                        <Badge color="primary" variant="dot" />
                    ) : (
                        <Badge badgeContent={badge} />
                    )}
                    {t(name)}
                </Button>
                <Collapse in={menuToggle}>{children}</Collapse>
            </ListItem>
        );
    }

    return (
        <ListItem component="div" key={name} {...rest}>
            <Button disableRipple component={RouterLink} onClick={closeSidebar} to={link ?? ''} startIcon={icon}>
                {t(name)}
                {badgeTooltip ? (
                    <TooltipWrapper title={badgeTooltip} arrow placement="right">
                        {badge === '' ? <Badge color="primary" variant="dot" /> : <Badge badgeContent={badge} />}
                    </TooltipWrapper>
                ) : badge === '' ? (
                    <Badge color="primary" variant="dot" />
                ) : (
                    <Badge badgeContent={badge} />
                )}
            </Button>
        </ListItem>
    );
};

SidebarMenuItem.propTypes = {
    children: PropTypes.node,
    active: PropTypes.bool,
    link: PropTypes.string,
    icon: PropTypes.elementType,
    badge: PropTypes.string,
    badgeTooltip: PropTypes.string,
    open: PropTypes.bool,
    name: PropTypes.string.isRequired
};

SidebarMenuItem.defaultProps = {
    open: false,
    active: false
};

export default SidebarMenuItem;
