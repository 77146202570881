import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { AdvanceClockPayload, PaymentInfo, TestClock } from 'src/models/payment';
import { axiosBaseQuery } from 'src/redux';

export const paymentApiService = createApi({
    reducerPath: 'paymentApiService',
    refetchOnMountOrArgChange: true,
    baseQuery: axiosBaseQuery(),
    endpoints: (builder) => ({
        getPaymentInfo: builder.query<PaymentInfo, string>({
            query: (id) => ({ url: `payments/user/${id}`, method: 'get' })
        }),
        getTestClock: builder.query<TestClock, string>({
            query: (customerId) => ({ url: `payments/test_clocks/customer/${customerId}`, method: 'get' })
        }),
        advanceTestClock: builder.mutation<string, AdvanceClockPayload>({
            query: ({ user_id, frozen_time }) => ({
                url: `payments/test_clocks/${user_id}/advance`,
                method: 'post',
                data: {
                    frozen_time
                }
            })
        })
    })
});

export const { useGetPaymentInfoQuery, useGetTestClockQuery, useAdvanceTestClockMutation } = paymentApiService;
